


const SearchModule = () => import(/* webpackChunkName: "search-module" */ './views/Module.vue');
const Search = () => import(/* webpackChunkName: "search" */ './views/Search.vue');


const SearchRoutes = {
    path: '/',
    component: SearchModule,
    children: [
        {
            path: 'search',
            name: 'search',
            component: Search,
        }
    ],
}

export default SearchRoutes;
